@import 'assets/scss/app.scss';

html.color-1 form.needs-validation .form-row {
    select~span {
        color: #ff5370;
    }

    textarea~span {
        color: #ff5370;
    }
}

.field-icon {
    float     : right;
    left      : -10px;
    margin-top: -25px;
    position  : relative;
    z-index   : 2;
}

.react-datepicker-wrapper {
    width: 100%;
}

.hidden-element {
    display: none
}

.ck-editor__editable_inline {
    min-height: 400px;
    color     : #000000;

    p {
        color: #000000;
    }
}

.profile-dropdown {
    width: 200px !important;
}

.react-pdf__Page canvas {
    width : 100% !important;
    height: auto !important;
}